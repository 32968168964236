<template>
  <v-main
    class="fill-height"
    fluid
   style="margin: auto; padding: 0px; width: 90%"
  >
    <v-row>
      <v-col>
         <v-card
    class="mx-auto"
    
    fill-height
   >
       <v-card-title>Websites</v-card-title>

    <v-list-item>
      <v-list-item-content>
    

        <!-- <tr
          v-for="domain in domains"
          :key="domain.domainname"
        >
          <td> <a :href="'/sites/' + domain.domainname"> {{ domain.domainname }} </a> </td>
    
        </tr> -->

    <v-list v-if="domainsLoaded">
      <v-list-group
        v-for="domain in domains"
        :key="domain.domainname"
        v-model="domain.active"
        no-action
      >
      <template v-slot:prependIcon>
        <img width="20px" height="20px" src="/favicon.png"> 
        </template>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="domain.domainname"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="site in domain.sites"
          :key="site.id"
        >
           <v-list-item-icon>
              <v-icon>mdi-circle-small</v-icon> 
            </v-list-item-icon>
        
            <v-list-item-content>
              <a :href="'/sites/' + site.uri">
              <v-list-item-title v-text="site.uri"> </v-list-item-title>
              </a>
            </v-list-item-content>
            <v-list-item-icon>
              [{{site.environment}}]
            </v-list-item-icon>
        </v-list-item>
      </v-list-group>
    </v-list>
     <v-progress-circular
      align-center
      indeterminate
      color="primary"
      :size="70"
      :width="7"
      v-else
    ></v-progress-circular>

  
      </v-list-item-content>

    </v-list-item>
 <v-card-actions class="text-right ml-4" >
   {{ sites }} of {{ site_limit }} sites used
    </v-card-actions>
      </v-card>
    </v-col>
  
    </v-row>
    <v-row>
        <v-col>
          <v-card class="mx-auto">
         <v-list-item-content>
    
        <v-list-item-title class="headline mb-1 ml-4">
          <v-row>
               <v-col class="overline mb-2 align-left">
               Total Unique Visits
              </v-col>
              <v-col style="text-align: right" class="overline mr-2 align-right">
                Last 7 days 
                <!-- To change this to a calendar option, start and end date , on change update graph-->
              </v-col>
            </v-row>
       
         
        </v-list-item-title>
      
        <line-chart v-if="chartLoaded" :chart-data="chart_data" :options="options" />



      </v-list-item-content>

            </v-card>
          </v-col>
          </v-row>
          <v-row v-if="payment_method == 'stripe'">
            <v-col>
                <v-card class="mx-auto">
         <v-list-item-content>
    
        <v-list-item-title class="headline mb-1 ml-4">
          Billing Section
        </v-list-item-title>
        <v-col>
            <v-btn
            dark

              @click="getCustomerPortal"
            >
              Open Stripe Billing Page
            </v-btn>

            </v-col>
      </v-list-item-content>

            </v-card>
       
            </v-col>
           </v-row>
              <v-overlay
          v-if="overlay"
          :value="overlay"
          z-index=0 
         >

        <v-row class="text-center">
          <v-col text-center>
            <v-progress-circular
            align-center
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row>
                      Redirecting to stripe billing...

          </v-row>
       </v-overlay>
</v-main>

</template>
<style>

</style>
<script>

import LineChart from "@/components/LineChart";
import moment from "moment"

export default {
   components: {
    LineChart,
  },
  data: () => ({
    // name: localStorage.getItem('name'),
    overlay: false,
    sites: "",
    site_limit: "",
    subscription_status: "",
    next_billing_date: "",
    domains: [],
    chartLoaded: false,
    domainsLoaded: false,
    expanded: [],
    payment_method:"",
    domainsHeaders: [
          {
            text: 'Domain Name',
            sortable: true,
            value: 'domainname',
          }
    ], 


    chart_data: {
         datasets: [],
    },

   options: {
     plugins: {
       datalabels: {
         formatter: () => {
           return "";
         },
         color: "white",
       },
     },
     responsive: true,
     maintainAspectRatio: false,
     scales: {
       xAxes: [
         {
           type: "category",
           labels: [],
         },
       ],
       yAxes: [
         {
           id: "A",
           type: "linear",
           position: "left",
           ticks: {
             beginAtZero: true,
           },
         },
       ],
     },
    }
  }
  ),
  created () {
    
       this.$store.dispatch('userDetails')
        .then( response => {
          
          this.domains = response.data.domains
            console.log(response.data)
          this.domainsLoaded = true
          this.payment_method = response.data.payment_method
          this.site_limit = response.data.plan.site_limit
          this.sites = response.data.domains.length
        })
        .catch(err => {
             console.log(err)
        })

        var visitsObj = {
            label: "Visits",
            data: [],
            borderColor: "rgba(255, 56, 96, 0.5)",
            backgroundColor: "rgba(255, 56, 96, 0.1)",
            yAxisID: "A",
            hidden: false,
          };
        //GET unique Visits
        var date =  moment()

        this.$store.dispatch('getAllVisits',{end_date:  moment().format('YYYY-MM-DD') ,  start_date: moment().add(-6, 'days').format('YYYY-MM-DD') } )
                .then( response => {
                     //Loop through viists data for the past 7 days
             
                    var domains = response.data 
                    var visits = 0
                    for(var i =6; i>-1;i--){
                           date = moment()
                           date = moment(date).add(-(i), 'days');
                           this.options.scales.xAxes[0].labels.push(
                           date.format('dddd')
                           );
                           var total_visits = 0
                           for(var domain in domains){
                               visits = domains[domain][date.format('YYYY-MM-DD')]
                               if(visits){
                                       total_visits += visits
                               }

                           }
                           visitsObj.data.push(total_visits);
                           
                    }
                            this.chart_data.datasets.push(visitsObj);
                            this.chartLoaded = true



          })
          .catch(err => {
                    console.log(err)
          })
       







  },
  methods: {
       getCustomerPortal() {
         this.overlay = true
         this.$store.dispatch('getCustomerPortal')
            .then( (resp) => {
                console.log(resp.data)
                window.open(resp.data, '_blank');
                this.overlay = false
            })
        },

  }

}
</script>
